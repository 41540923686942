import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Video from "twilio-video";

const LocalVideoComponent = styled.video`
  height: 100%;
  display: block;
  margin: 0 auto;
`;

const VideoComponent = styled.video`
  /* width: 100%;
  height: 100%;
  margin: 0 auto; */

  /* height: 100%; */
  /* display: block; */
  /* margin: 0 auto; */
  /* overflow: hidden; */
  /* margin-left: auto;
  margin-right: auto; */
  /* width: 50%; */
  flex: 1;
`;

const OuterVideoComponent = styled.div`
  flex: 1;
  display: flex;
`;

interface Props {
  isLocal: boolean;
  participant: Video.Participant;
}

type Track = Video.AudioTrack | Video.VideoTrack;

type Publication = Video.AudioTrackPublication | Video.VideoTrackPublication;

type Publications = Map<Video.Track.SID, Publication>;

export const Participant: FunctionComponent<Props> = ({
  participant,
  isLocal,
}) => {
  const [videoTracks, setVideoTracks] = useState<Track[]>([]);
  const [audioTracks, setAudioTracks] = useState<Track[]>([]);

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const trackpubsToTracks: (trackMap: Publications) => Track[] = (
    trackMap: Publications,
  ) => {
    const values = trackMap.values();
    return Array.from(values)
      .map(publication => publication.track as Track)
      .filter(track => track !== null);
  };

  useEffect(() => {
    const trackSubscribed = track => {
      if (track.kind === "video") {
        setVideoTracks(videoTracks => [...videoTracks, track]);
      } else {
        setAudioTracks(audioTracks => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = track => {
      if (track.kind === "video") {
        setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
      } else {
        setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  }, [participant]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <>
      <VideoComponentWrapper isLocal={isLocal} videoRef={videoRef} />
      <audio ref={audioRef} autoPlay={true} />
    </>
  );
};

const VideoComponentWrapper: FunctionComponent<{
  isLocal: boolean;
  videoRef: React.RefObject<HTMLVideoElement>;
}> = ({ isLocal, videoRef }) => {
  if (isLocal) {
    return <LocalVideoComponent ref={videoRef} autoPlay={true} />;
  } else {
    return (
      <OuterVideoComponent>
        <VideoComponent ref={videoRef} autoPlay={true} />
      </OuterVideoComponent>
    );
  }
};
