/* eslint-disable no-restricted-globals */
import React, { FunctionComponent, useState, useCallback } from "react";
import { Logo, SofiaIcon } from "./icons";
import { useHistory } from "react-router-dom";
import {
  RootContainer,
  MainContainer,
  OuterSubscriberContainer,
  WaitingMessage,
  SubscriberContainer,
  TwilioPublisherBox,
  Toolbar,
  CameraButton,
  AudioButton,
} from "./video-components";
import { useEffect } from "react";
import Video from "twilio-video";
import { Participant } from "./TwilioParticipant";
import { flattenObject } from "./util";
import { mixpanel } from "./MixpanelService";
import strings from "./strings";

export const TwilioVideoCall = () => {
  let params = new URL(location.toString()).searchParams;

  const token = params.get("token") || "";
  const consultId = params.get("consultId") || "Unknown";
  const userType = params.get("userType") || "Unknown";

  // console.log("Token: ", token);

  // // const roomName = "Labs";
  // // const [token, setToken] = useState(
  // //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzI3NWNkNDRhN2I0MGJlMmQ1ODhlZTQxN2IwMTk3OTRkLTE1OTEzMDU3NTUiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJKb2UiLCJ2aWRlbyI6eyJyb29tIjoiVGVzdCJ9fSwiaWF0IjoxNTkxMzA1NzU1LCJleHAiOjE1OTEzOTIxNTUsImlzcyI6IlNLMjc1Y2Q0NGE3YjQwYmUyZDU4OGVlNDE3YjAxOTc5NGQiLCJzdWIiOiJBQ2NiZTczZjY1OWMxZjdmYTU2N2IxYjFmYWY3YjJmNzY2In0.Kiwjm887HGrOtcyGXVTIvMxsTW4GE_iBdwxgMy0zCxo",
  // // );
  const [waitingMessage, setWaitingMessage] = useState("");
  const history = useHistory();
  const [isMuted, setIsMuted] = useState(false);
  const [isCameraDisabled, setIsCameraDisabled] = useState(false);
  const [participants, setParticipants] = useState<Video.Participant[]>([]);
  const [room, setRoom] = useState<Video.Room | null>(null);

  const track = useCallback(
    (eventName: string, obj: any) => {
      const jsonObj = JSON.parse(JSON.stringify(obj));
      const payload = flattenObject({
        token,
        roomType: "Twilio",
        consultId,
        userType,
        ...jsonObj,
      });
      console.log("Event: ", eventName);
      mixpanel.track(eventName, payload);
    },
    [token, consultId, userType],
  );

  useEffect(() => {
    track("Twilio Video Start", {});
    // }, [token, roomId, consultId, track]);
  }, [token, consultId, track]);

  let hangUpButton = (
    <SofiaIcon
      type={"HangUp"}
      onClick={() => {
        track("Video hang up", {});
        history.push(`/feedback?consultId=${consultId}&userType=${userType}`);
      }}
    />
  );

  const remoteParticipants = participants.map(participant => (
    <Participant
      key={participant.sid}
      participant={participant}
      isLocal={false}
    />
  ));

  useEffect(() => {
    (async () => {
      const handleMemberAdded = (member: Video.Participant) => {
        const memberInfo = JSON.parse(JSON.stringify(member));
        // console.log(JSON.stringify(roomInfo, null, 2));
        delete memberInfo["tracks"];
        delete memberInfo["audioTracks"];
        delete memberInfo["videoTracks"];
        track("Twilio Video Member Joined", { member: memberInfo });

        // console.log("----- handleMemberAdded", member);
        setParticipants(prevParticipants => {
          const updatedParticipants = [...prevParticipants, member];
          if (updatedParticipants.length) {
            setWaitingMessage("");
          }
          return updatedParticipants;
        });
      };
      const handleMemberRemoved = (member: Video.Participant) => {
        const memberInfo = JSON.parse(JSON.stringify(member));
        // console.log(JSON.stringify(roomInfo, null, 2));
        delete memberInfo["tracks"];
        delete memberInfo["audioTracks"];
        delete memberInfo["videoTracks"];
        track("Twilio Video Member Removed", { member: memberInfo });
        // console.log("----- handleMemberRemoved", member);
        setParticipants(prevParticipants => {
          const updatedParticipants = prevParticipants.filter(
            p => p !== member,
          );

          if (!updatedParticipants.length) {
            setWaitingMessage(strings.waitingForOtherPerson);
          }

          return updatedParticipants;
        });
      };

      setWaitingMessage(strings.connectingToServer);

      try {
        // const room = await Video.connect(token, { name: roomId });
        const room = await Video.connect(token, {
          // networkQuality: true,
          networkQuality: {
            local: 2, // LocalParticipant's Network Quality verbosity [1 - 3]
            remote: 2, // RemoteParticipants' Network Quality verbosity [0 - 3]
          },
        });

        setWaitingMessage(strings.waitingForOtherPerson);

        const roomInfo = JSON.parse(JSON.stringify(room));
        // console.log(JSON.stringify(roomInfo, null, 2));
        delete roomInfo["localParticipant"]["tracks"];
        delete roomInfo["localParticipant"]["audioTracks"];
        delete roomInfo["localParticipant"]["videoTracks"];
        delete roomInfo["localParticipant"]["dataTracks"];
        delete roomInfo["participants"];
        // console.log(JSON.stringify(roomInfo, null, 2));

        track("Twilio Video Room Connected", {
          room: roomInfo,
        });

        // console.log("Connecting to room", room);
        room.on("participantConnected", handleMemberAdded);
        room.on("participantDisconnected", handleMemberRemoved);
        room.participants.forEach(handleMemberAdded);
        room.localParticipant.on(
          "networkQualityLevelChanged",
          (networkQualityLevel, networkQualityStats) => {
            if (networkQualityStats) {
              track("Twilio Stats", networkQualityStats);
            }
          },
        );

        setRoom(room);
      } catch (e) {
        track("Twilio Video Error", e);
        setWaitingMessage("Error " + e.message);
      }

      // Print the initial Network Quality Level and statistics
      // printNetworkQualityStats(participant.networkQualityLevel, participant.networkQualityStats);

      // track("Twilio Video Room Connected", {
      //   userId: room.localParticipant.sid,
      // });
    })();

    return () => {
      setRoom(currentRoom => {
        console.log("Clean up - ", currentRoom);
        if (currentRoom === null) {
          return null;
        }

        // console.log(
        //   "currentRoom.localParticipant.state - ",
        //   currentRoom.localParticipant.state,
        // );
        if (currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(trackPublication => {
            const mediaTrack: any = trackPublication.track;
            if (mediaTrack.stop) {
              // Stop does exist on type. Need to update.
              // console.log("--- track stop ", mediaTrack);
              mediaTrack.stop();
            }
          });
          currentRoom.disconnect();
        }

        return null;
      });
    };
    // }, [roomId, token, track]);
  }, [token, track]);

  // let localParticipant: JSX.Element | null = null;
  // if (room) {
  //   localParticipant = (
  //     <Participant
  //       key={room.localParticipant.sid}
  //       participant={room.localParticipant}
  //       isLocal={true}
  //     />
  //   );
  // }
  // <TwilioPublisherBox>{localParticipant}</TwilioPublisherBox>

  return (
    <RootContainer>
      <MainContainer>
        <OuterSubscriberContainer>
          <WaitingMessage>{waitingMessage}</WaitingMessage>
          <SubscriberContainer>{remoteParticipants}</SubscriberContainer>
        </OuterSubscriberContainer>
        <TwilioPublisherBox>
          <LocalParticipant room={room} />
        </TwilioPublisherBox>
      </MainContainer>
      <Toolbar>
        <Logo />
        <CameraButton
          isCameraDisabled={isCameraDisabled}
          setIsCameraDisabled={isCameraDisabled => {
            setIsCameraDisabled(isCameraDisabled);
            if (!room) {
              return;
            }

            const publisher = Array.from(
              room.localParticipant.videoTracks.values(),
            );
            if (!publisher.length || !publisher[0]) {
              return;
            }
            const track = publisher[0].track;
            if (isCameraDisabled) {
              track.disable();
            } else {
              track.enable();
            }
          }}
        />
        <AudioButton
          isMuted={isMuted}
          setIsMuted={isMuted => {
            setIsMuted(isMuted);
            if (!room) {
              return;
            }

            const publisher = Array.from(
              room.localParticipant.audioTracks.values(),
            );
            if (!publisher.length || !publisher[0]) {
              return;
            }
            const track = publisher[0].track;
            if (isMuted) {
              console.log("MUTE");
              track.disable();
            } else {
              console.log("UNMUTE");
              track.enable();
            }
          }}
        />
        {hangUpButton}
      </Toolbar>
    </RootContainer>
  );
};

const LocalParticipant: FunctionComponent<{ room: Video.Room | null }> = ({
  room,
}) => {
  if (room) {
    return (
      <Participant
        key={room.localParticipant.sid}
        participant={room.localParticipant}
        isLocal={true}
      />
    );
  }

  return null;
};
