import * as React from "react";

const SvgMute = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M12 19.5v3.75M16.5 13.5V3.75a3 3 0 00-3-3h-3a3 3 0 00-3 3m6 12.75h-3a3 3 0 01-3-3V10M3 2.5l18 18.75"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 9.75V12a7.5 7.5 0 01-.905 3.572M4.5 9.75V12a7.5 7.5 0 0010.87 6.7"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgMute;
