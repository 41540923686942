import * as React from "react";
import styled from "styled-components";

const {
  OTSession,
  OTPublisher,
  OTStreams,
  OTSubscriber,
  preloadScript,
} = require("opentok-react");

const RootContainer = styled.div`
  padding: 2em 2em 2em 2em;
`;

export interface Props {
  isRouted: boolean;
}

export const VideoChatRoomNoPreload: React.FC<Props> = (props: Props) => {
  let apiKey = "46200212";
  let sessionId =
    "1_MX40NjIwMDIxMn5-MTU4OTgyNzQ0ODk4Nn5KTSs3Vy9IbmZFWEJJcEs5anc0dkhhYTV-fg";
  let token =
    "T1==cGFydG5lcl9pZD00NjIwMDIxMiZzaWc9NzEzZGRkMTdiZTI4NTBmMzcyY2FjOGZkZDUwM2U1ZTcxMGY1MWFkNDpzZXNzaW9uX2lkPTFfTVg0ME5qSXdNREl4TW41LU1UVTRPVGd5TnpRME9EazRObjVLVFNzM1Z5OUlibVpGV0VKSmNFczVhbmMwZGtoaFlUVi1mZyZjcmVhdGVfdGltZT0xNTg5ODI3NDU5Jm5vbmNlPTAuNDg1NTc4MDk0MzUzNjU4NSZyb2xlPXB1Ymxpc2hlciZleHBpcmVfdGltZT0xNTkyNDE5NDU5JmluaXRpYWxfbGF5b3V0X2NsYXNzX2xpc3Q9";
  let mode = "Routed";

  if (!props.isRouted) {
    sessionId =
      "2_MX40NjIwMDIxMn5-MTU4OTkzNjIyOTAyNX50M0h0bVZpZU9yQ3MwcHFwY2lxV295UjN-UH4";
    token =
      "T1==cGFydG5lcl9pZD00NjIwMDIxMiZzaWc9NTBiN2Q1YzlkMjFlYWRhY2ZkZWFmOTFhZGFlOTEwYzk0ZTcwMzc0MjpzZXNzaW9uX2lkPTJfTVg0ME5qSXdNREl4TW41LU1UVTRPVGt6TmpJeU9UQXlOWDUwTTBoMGJWWnBaVTl5UTNNd2NIRndZMmx4VjI5NVVqTi1VSDQmY3JlYXRlX3RpbWU9MTU4OTkzNjI1NyZub25jZT0wLjU4MzI4MTAxNjI2NDM3NDUmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTU5MjUyODI1NiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==";
    mode = "Relayed";
  }

  // console.log("props: ", props);

  return (
    <RootContainer>
      <div>Video Chat - Mode: {mode}</div>
      <OTSession apiKey={apiKey} sessionId={sessionId} token={token}>
        <OTPublisher />
        <OTStreams>
          <OTSubscriber />
        </OTStreams>
      </OTSession>
    </RootContainer>
  );
};

export const VideoChatRoom: React.FC<Props> = preloadScript(
  VideoChatRoomNoPreload,
);
