import React, { FunctionComponent } from "react";
import MuteIcon from "./svgComponents/Mute";
import UnmuteIcon from "./svgComponents/Unmute";
import CameraOffIcon from "./svgComponents/CameraOff";
import CameraOnIcon from "./svgComponents/CameraOn";
import LogoIcon from "./svgComponents/Logo";
import HangUpIcon from "./svgComponents/HangUp";
import ErrorIcon from "./svgComponents/Error";
import styled, { StyledComponent, css } from "styled-components";
import { iconWhite, iconGray, iconRed, sofiaPurple } from "./colors";

export type SofiaIconType =
  | "Mute"
  | "Unmute"
  | "CameraOn"
  | "CameraOff"
  | "HangUp"
  | "Error";

export const LogoContainer = styled.div`
  svg {
    path {
      fill: white;
    }
  }
`;

export const PurpleLogoContainer = styled.div`
  svg {
    path {
      fill: ${sofiaPurple};
    }
  }
`;

export const Logo = () => {
  return (
    <LogoContainer>
      <LogoIcon />
    </LogoContainer>
  );
};

export const PurpleLogo = () => {
  return (
    <PurpleLogoContainer>
      <LogoIcon />
    </PurpleLogoContainer>
  );
};

export interface SofiaIconProps {
  type: SofiaIconType;
  onClick?: () => void;
}

export const icons: {
  [key: string]: StyledComponent<"div", any, {}, never>;
} = {
  Mute: createIconContainer("Mute"),
  Unmute: createIconContainer("Unmute"),
  CameraOn: createIconContainer("CameraOn"),
  CameraOff: createIconContainer("CameraOff"),
  HangUp: createIconContainer("HangUp"),
  Error: createIconContainer("Error"),
};

export function createIconContainer(
  type: SofiaIconType,
): StyledComponent<"div", any, {}, never> {
  const common = css`
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
  `;

  switch (type) {
    case "Mute": {
      const Container = styled.div`
      ${common}
      background-color: ${iconWhite};
      svg {
        path {
          stroke: #1A1A1A;
        }
      }
    `;

      return Container;
    }
    case "Unmute": {
      const Container = styled.div`
          ${common}
          background-color: ${iconGray};
          svg {
            path {
              stroke: white;
            }
          }
        `;

      return Container;
    }
    case "CameraOff": {
      const Container = styled.div`
          ${common}
          background-color: ${iconWhite};
          svg {
            path {
              stroke: #1A1A1A;
            }
          }
        `;

      return Container;
    }
    case "CameraOn": {
      const Container = styled.div`
          ${common}
          background-color: ${iconGray};
          svg {
            path {
              stroke: white;
            }
          }
        `;

      return Container;
    }
    case "HangUp": {
      const Container = styled.div`
          ${common}
          background-color: ${iconRed};
          svg {
            path {
              stroke: white;
            }
          }
        `;

      return Container;
    }
    case "Error": {
      const Container = styled.div`
          ${common}
          background-color: ${iconRed};
          svg {
            path {
              stroke: white;
            }
          }
        `;

      return Container;
    }
  }
}

export const SofiaIcon: FunctionComponent<SofiaIconProps> = props => {
  switch (props.type) {
    case "Mute": {
      const Container = icons[props.type];

      return (
        <Container onClick={props.onClick}>
          <MuteIcon />
        </Container>
      );
    }
    case "Unmute": {
      const Container = icons[props.type];

      return (
        <Container onClick={props.onClick}>
          <UnmuteIcon />
        </Container>
      );
    }
    case "CameraOff": {
      const Container = icons[props.type];

      return (
        <Container onClick={props.onClick}>
          <CameraOffIcon />
        </Container>
      );
    }
    case "CameraOn": {
      const Container = icons[props.type];

      return (
        <Container onClick={props.onClick}>
          <CameraOnIcon />
        </Container>
      );
    }
    case "HangUp": {
      const Container = icons[props.type];

      return (
        <Container onClick={props.onClick}>
          <HangUpIcon />
        </Container>
      );
    }
    case "Error": {
      const Container = icons[props.type];
      return (
        <Container>
          <ErrorIcon />
        </Container>
      );
    }
  }
};
