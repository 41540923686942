export const mixpanel = require("mixpanel-browser");

function main() {
  try {
    const token = process.env.REACT_APP_MIXPANEL_TOKEN;
    mixpanel.init(token);
  } catch (e) {}
}

main();
