import * as React from "react";

const SvgLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={128} height={44} fill="none" {...props}>
    <path
      d="M15.604 23.444l-2.73-.68c-2.806-.756-4.789-1.26-4.789-3.248 0-1.839 1.571-2.922 3.863-2.922 2.755 0 4.944 1.335 5.201 4.86h7.184c-.386-6.119-4.557-10.299-12.463-10.299-5.793 0-10.943 3.022-10.943 8.738 0 5.616 4.455 7.152 8.369 8.058l2.755.68c3.167.806 5.15 1.712 5.15 3.878 0 2.065-1.983 2.921-4.275 2.921-2.987 0-5.33-1.209-5.742-4.28H0c.412 5.892 4.738 9.72 12.978 9.72 6.205 0 11.355-2.62 11.355-8.512 0-5.364-3.63-7.655-8.729-8.914zM93.753 12.641h-7.467v27.397h7.467V12.641zM127.305 34.876c-1.391 0-2.086-.68-2.086-2.165V22.286c0-7.63-5.536-11.005-12.36-11.005-7.467 0-12.102 4.155-12.694 10.073h7.467c.464-2.569 2.086-4.281 5.253-4.281 2.858 0 4.892 1.536 4.892 4.659v1.208l-9.115.957c-5.356.504-9.604 3.198-9.604 8.663 0 5.64 4.377 8.385 10.299 8.385 4.609 0 7.622-1.536 9.605-3.979.978 2.62 3.141 3.425 5.871 3.425h3.141v-5.465h-.669v-.05zm-9.553-5.514c0 4.507-3.966 5.993-7.107 5.993-2.318 0-4.146-.806-4.146-3.072 0-2.216 2.034-3.3 4.712-3.576l6.515-.68v1.334h.026zM46.916 40.468c8.193-1.892 13.267-9.921 11.332-17.934C56.314 14.522 48.103 9.56 39.91 11.452c-8.193 1.892-13.266 9.921-11.332 17.934 1.935 8.012 10.145 12.974 18.338 11.082zM90.715 1.108L87.29 8.99h5.073c.386 0 .746-.202.952-.529L98.362.428H91.72a1.19 1.19 0 00-1.004.68zM80.492.68c-.051-.025-.129-.025-.18-.05-.026 0-.052 0-.077-.026A18.407 18.407 0 0076.243 0h-.463c-5.665 0-9.63 2.921-9.63 8.788v3.828h-6.051v5.69h6.05v21.757h7.39V18.357h7.494v-5.716H73.54V9.216c0-2.367 1.442-3.45 3.579-3.45 1.777 0 3.605.655 4.532.932l1.261-5.237c-.463-.152-1.055-.378-1.725-.58-.231-.075-.463-.126-.695-.201z"
      fill="#4A25AA"
    />
  </svg>
);

export default SvgLogo;
