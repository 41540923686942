import React from "react";
import styled from "styled-components";
import { sofiaPurple } from "./colors";
import { SofiaIcon } from "./icons";

export const Body = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${sofiaPurple};
  color: #FFFFFF;
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
`;

export const WaitingBody = styled.div`
  display: flex;
  height: auto;
`;

export const WaitingMessage = styled.div`
  
`;

export const WaitingIcon = styled.div`
  padding-right: 20px;
  display: flex;
`;

export const IntercomTriggerContainer = styled.div`
  display: flex;
  z-index: 1000;
  align-items: center;
`;

export const IntercomTrigger = styled.span`
  font-weight: 700;
  margin-right: 5px;
`;

export const SofiaLogo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
`;

export const NameContainer = styled.div`
  color: #FFFFFF;
  width: 150px;
`;

export const Name = styled.p`
  font-weight: 700;
`;

export const Title = styled.p`
  margin-top: 10px;
`;

export const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  position: relative;
  flex: 1;
`;

export const SubscriberContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const OuterSubscriberContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const PublisherBox = styled.div`
  background-color: ${sofiaPurple};

  width: 117px;
  height: 117px;
  position: absolute;
  right: 10px;
  bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
`;

export const TwilioPublisherBox = styled.div`
  width: 117px;
  height: 117px;
  position: absolute;
  right: 10px;
  bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
`;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: orange;
  background-color: ${sofiaPurple};
  width: 100%;
  height: 100px;
`;

export const CameraButton = (props: {
  isCameraDisabled: boolean;
  setIsCameraDisabled: (isCameraDisabled: boolean) => void;
}) => {
  if (props.isCameraDisabled) {
    return (
      <SofiaIcon
        type="CameraOff"
        onClick={() => {
          props.setIsCameraDisabled(false);
        }}
      />
    );
  } else {
    return (
      <SofiaIcon
        type="CameraOn"
        onClick={() => {
          props.setIsCameraDisabled(true);
        }}
      />
    );
  }
};

export const AudioButton = (props: {
  isMuted: boolean;
  setIsMuted: (isMuted: boolean) => void;
}) => {
  if (props.isMuted) {
    return (
      <SofiaIcon
        type="Mute"
        onClick={() => {
          props.setIsMuted(false);
        }}
      />
    );
  } else {
    return (
      <SofiaIcon
        type="Unmute"
        onClick={() => {
          props.setIsMuted(true);
        }}
      />
    );
  }
};
