/* eslint-disable no-restricted-globals */
import React, {
  useState,
  useCallback,
  useEffect,
  FunctionComponent,
} from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { firebaseService, FallbackConfig } from "./firebase-service";
import { mixpanel } from "./MixpanelService";

const Container = styled.div`
  padding: 2em 2em 2em 2em;
  border: 1px solid black;
  margin: 20px;
  background-color: aliceblue;
`;

const HighlightedContainer = styled.div`
  padding: 2em 2em 2em 2em;
  border: 1px solid black;
  margin: 20px;
  background-color: #8bc34a;
`;

const Response = styled.div`
  padding: 2em 2em 2em 0.5em;
  border-color: black;
  border-width: 1px;
`;

const Title = styled.h4`
  padding-bottom: 10px;
`;

const InstructionTitle = styled.h4`
  padding-bottom: 10px;
  margin: 20px;
`;

const Instructions = styled.div`
  padding-bottom: 10px;
  margin: 20px;
`;

const Item = styled.p`
  padding-bottom: 10px;
`;

interface Props {
  consultId: string;
  autoSubmit?: boolean;
}

interface OpenTokProps {
  consultId: string;
  autoSubmit?: boolean;
  roomType: RoomType | null;
}

interface OpenTokFormData {
  sessionType?: "routed" | "relayed";
}

const CreateOpenTokRoom: FunctionComponent<OpenTokProps> = ({
  consultId,
  autoSubmit,
  roomType,
}) => {
  const { register, handleSubmit } = useForm();
  // const [roomUrl, setRoomUrl] = useState("");
  const [response, setResponse] = useState<string | OpenTokResponse | null>(
    null,
  );

  const onSubmit = useCallback(
    (data: OpenTokFormData) => {
      (async () => {
        const roomType =
          data.sessionType === "relayed" ? "OpenTokRelay" : "OpenTokRouted";
        await createRoomOpenTok({
          setResponse,
          roomType,
          consultId,
        });
      })();
    },
    [consultId],
  );

  let formElement = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <select name="sessionType" ref={register}>
        <option value="routed">Routed</option>
        <option value="relayed">Relayed </option>
      </select>
      <div>
        <input type="submit" value="Create Video Call Room" />
      </div>
    </form>
  );

  return (
    <Container>
      <Title>Create Video Call Room (OpenTok)</Title>
      {formElement}
      <OpenTokRoomInfo response={response} consultId={consultId} />
    </Container>
  );
};

const CreateTwiloRoom: FunctionComponent<Props> = ({ consultId }) => {
  const { handleSubmit } = useForm();
  const [response, setResponse] = useState<
    string | null | { token1: string; token2: string; roomId: string }
  >(null);

  const onSubmit = useCallback(() => {
    (async () => {
      createRoomTwilio({ setResponse, roomType: "Twilio", consultId });
    })();
  }, [consultId]);

  // useEffect(() => {
  //   if (autoSubmit && onSubmit) {
  //     onSubmit();
  //   }
  // }, [autoSubmit, onSubmit]);

  let formElement = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <input type="submit" value="Create Video Call Room" />
      </div>
    </form>
  );

  return (
    <Container>
      <Title>Create Video Call Room (Twilio)</Title>
      {formElement}
      <TwilioRoomInfo response={response} consultId={consultId} />
    </Container>
  );
};

const CreateZoomRoom: FunctionComponent<{}> = () => {
  const { handleSubmit } = useForm();
  const [response, setResponse] = useState<string | null | ZoomResponse>(null);

  const onSubmit = useCallback(() => {
    (async () => {
      createRoomZoom({ setResponse });
    })();
  }, []);

  let formElement = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <input type="submit" value="Create Video Call Room" />
      </div>
    </form>
  );

  return (
    <Container>
      <Title>Create Video Call Room (Zoom)</Title>
      {formElement}
      <ZoomRoomInfo response={response} />
    </Container>
  );
};

type RoomType = "OpenTokRouted" | "OpenTokRelay" | "Twilio";

interface ZoomResponse {
  response: {
    join_url: string;
    start_url: string;
  };
}

interface TwilioResponse {
  token1: string;
  token2: string;
  roomId: string;
}
interface OpenTokResponse {
  sessionId: string;
  token: string;
  apiKey: string;
  sessionType: string;
}

function isOpenTokResponse(input: any): input is OpenTokResponse {
  if (input && input.sessionId) {
    return true;
  }

  return false;
}

function isTwilioResponse(input: any): input is OpenTokResponse {
  if (input && input.roomId) {
    return true;
  }

  return false;
}

export const CreateRandomRoom: FunctionComponent<{
  roomType: RoomType;
  consultId: string;
  randomizeRoom: () => void;
  createdRoomType: RoomType | null;
  setCreatedRoomType: React.Dispatch<
    React.SetStateAction<"OpenTokRouted" | "OpenTokRelay" | "Twilio" | null>
  >;
}> = ({
  consultId,
  roomType,
  randomizeRoom,
  createdRoomType,
  setCreatedRoomType,
}) => {
  const { handleSubmit } = useForm();
  const [response, setResponse] = useState<
    string | null | OpenTokResponse | TwilioResponse
  >(null);

  const onSubmit = useCallback(() => {
    (async () => {
      await createRoom({ setResponse, roomType, consultId });
      setCreatedRoomType(roomType);
      randomizeRoom();
    })();
  }, [consultId, roomType, randomizeRoom, setCreatedRoomType]);

  let formElement = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <input type="submit" value="Create Video Call Room" />
      </div>
    </form>
  );

  let roomInfo: JSX.Element | null = null;
  if (response) {
    if (typeof response === "string") {
      roomInfo = <Response>{response}</Response>;
    } else if (isOpenTokResponse(response)) {
      roomInfo = OpenTokRoomInfo({ response, consultId });
    } else if (isTwilioResponse(response)) {
      roomInfo = TwilioRoomInfo({ response, consultId });
    }
  }

  return (
    <HighlightedContainer>
      <Title>
        Create Video Call with Random Room Type (Next random type: {roomType})
      </Title>
      {formElement}
      {roomInfo}
    </HighlightedContainer>
  );
};

type SetResponseCreateRoom = React.Dispatch<
  React.SetStateAction<string | TwilioResponse | OpenTokResponse | null>
>;

type SetResponseCreateRoomOpenTok = React.Dispatch<
  React.SetStateAction<string | OpenTokResponse | null>
>;

type SetResponseCreateRoomTwilio = React.Dispatch<
  React.SetStateAction<string | TwilioResponse | null>
>;

type SetResponseCreateRoomZoom = React.Dispatch<
  React.SetStateAction<string | ZoomResponse | null>
>;

const createRoomOpenTok = async (params: {
  setResponse: SetResponseCreateRoomOpenTok;
  roomType: RoomType;
  consultId: string;
}) => {
  const { setResponse, consultId, roomType } = params;
  if (roomType !== "OpenTokRelay" && roomType !== "OpenTokRouted") {
    throw new Error(`Invalid roomType: ${roomType}`);
  }
  const sessionType = roomType === "OpenTokRelay" ? "relayed" : "routed";
  mixpanel.track("Sabios createRoom request sent", { roomType, consultId });
  setResponse("Loading...");
  const url = "/createRoom";
  const fetchRsponse = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ sessionType }),
  });
  const response = await fetchRsponse.json();
  setResponse(response);
  if (consultId.startsWith("fallback")) {
    firebaseService.incrementFallbackCount();
  }
};

const createRoomZoom = async (params: {
  setResponse: SetResponseCreateRoomZoom;
}) => {
  const { setResponse } = params;

  setResponse("Loading...");
  const url = "/createZoomRoom";
  const fetchRsponse = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const text = await fetchRsponse.text();
  try {
    setResponse(JSON.parse(text));
  } catch (err) {
    console.log("Cannot parse: ", text);
    setResponse(err.message);
  }
};

const createRoomTwilio = async (params: {
  setResponse: SetResponseCreateRoomTwilio;
  roomType: RoomType;
  consultId: string;
}) => {
  const { setResponse, consultId, roomType } = params;
  if (roomType !== "Twilio") {
    throw new Error(`Invalid roomType: ${roomType}`);
  }
  mixpanel.track("Sabios createRoom request sent", { roomType, consultId });
  setResponse("Loading...");
  const url = "/createTwilioRoom";
  const fetchRsponse = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const response = await fetchRsponse.json();
  setResponse(response);
  if (consultId.startsWith("fallback")) {
    firebaseService.incrementFallbackCount();
  }
};

const OpenTokRoomInfo = (params: {
  response: string | OpenTokResponse | null;
  consultId: string;
}) => {
  const { response, consultId } = params;
  if (typeof response === "string") {
    return <Response>{response}</Response>;
  }

  if (!response) {
    return null;
  }

  const { sessionId, token, apiKey, sessionType } = response;

  const roomType: RoomType =
    sessionType === "routed" ? "OpenTokRouted" : "OpenTokRelay";

  const roomUrl1 = `${location.origin}/video-call?consultId=${consultId}&sessionType=${sessionType}&apiKey=${apiKey}&userType=Doctor&sessionId=${sessionId}&token=${token}`;
  const roomUrl2 = `${location.origin}/video-call?consultId=${consultId}&sessionType=${sessionType}&apiKey=${apiKey}&userType=Patient&sessionId=${sessionId}&token=${token}`;

  return (
    <Response>
      <Title>Room Type: {roomType}</Title>
      <Item>
        <a href={roomUrl1}>Doctor Video Call Link</a>
      </Item>
      <Item>
        <a href={roomUrl2}>Patient Video Call Link</a>
      </Item>
    </Response>
  );
};

const TwilioRoomInfo = (params: {
  response: string | TwilioResponse | null;
  consultId: string;
}) => {
  const { response, consultId } = params;
  if (typeof response === "string") {
    return <Response>{response}</Response>;
  }

  if (!response) {
    return null;
  }

  const roomType = "Twilio";

  const { token1, token2 } = response;

  const encode = (input: string): string => {
    return input.replace(/\./g, "%2E");
  };

  const roomUrl1 = `${
    location.origin
  }/video-call-twilio?consultId=${consultId}&userType=Doctor&token=${encode(
    token1,
  )}`;
  const roomUrl2 = `${
    location.origin
  }/video-call-twilio?consultId=${consultId}&userType=Patient&token=${encode(
    token2,
  )}`;
  return (
    <Response>
      <Title>Room Type: {roomType}</Title>
      <Item>
        <a href={roomUrl1}>Doctor Video Call Link</a>
      </Item>
      <Item>
        <a href={roomUrl2}>Patient Video Call Link</a>
      </Item>
    </Response>
  );
};

const ZoomRoomInfo = (params: { response: string | ZoomResponse | null }) => {
  const { response } = params;
  if (typeof response === "string") {
    return <Response>{response}</Response>;
  }

  if (response?.response) {
    const joinUrl = response.response.join_url;
    const startUrl = response.response.start_url;

    return (
      <Response>
        <Item>
          <a href={startUrl}>Doctor Video Call Link</a>
        </Item>
        <Item>
          <a href={joinUrl}>Patient Video Call Link</a>
        </Item>
      </Response>
    );
  }

  return null;
};

const createRoom = async (params: {
  setResponse: SetResponseCreateRoom;
  roomType: RoomType;
  consultId: string;
}) => {
  const { setResponse, consultId, roomType } = params;
  if (roomType === "OpenTokRelay" || roomType === "OpenTokRouted") {
    await createRoomOpenTok({
      setResponse: setResponse as SetResponseCreateRoomOpenTok,
      consultId,
      roomType,
    });
  } else {
    await createRoomTwilio({
      setResponse: setResponse as SetResponseCreateRoomTwilio,
      consultId,
      roomType,
    });
  }
};

export const CreateRoom = () => {
  const [fallbackConfig, setFallbackConfig] = useState<FallbackConfig | null>(
    null,
  );

  const [randomRoomType, setRandomRoomType] = useState<RoomType | null>(null);
  const [createdRoomType, setCreatedRoomType] = useState<RoomType | null>(null);

  const errorCallback = useCallback((errorMessage: string) => {
    console.log("---- ERROR", errorMessage);
  }, []);

  const randomizeRoom = useCallback(() => {
    const choices: RoomType[] = ["OpenTokRouted", "OpenTokRelay", "Twilio"];
    // const choices: RoomType[] = ["Twilio"];
    const index = Math.floor(Math.random() * choices.length);
    setRandomRoomType(choices[index]);
  }, []);

  useEffect(() => {
    randomizeRoom();
  }, [randomizeRoom]);

  const params = new URL(location.toString()).searchParams;
  let consultId = params.get("consultId") || "";

  useEffect(() => {
    mixpanel.track("Sabios createRoom page visted", { consultId });
  }, [consultId]);

  if (fallbackConfig && !consultId) {
    // console.log("I need a fallback");
    consultId = `fallback-${fallbackConfig.fallbackNumber}`;
  }

  // console.log("Fallback config: ", fallbackConfig);
  // console.log("consultId: ", consultId);
  // console.log("randomRoomType: ", randomRoomType);
  firebaseService.useFallbackConfig({
    resultCallback: setFallbackConfig,
    errorCallback,
  });

  return (
    <div>
      <Container>ConsultId: {consultId}</Container>
      <CreateZoomRoom />
      <CreateOpenTokRoom consultId={consultId} roomType={null} />
      <CreateTwiloRoom consultId={consultId} />
    </div>
  );
};
