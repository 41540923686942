/* eslint-disable no-restricted-globals */

import * as React from "react";
import styled from "styled-components";
import { Logo } from "./icons";
import { Toolbar } from "./video-components";

const strings = {
  itsOver: "La videoconsulta ha terminado",
  patientItsOver:
    "Regresa a tu app para recibir las indicaciones de parte de tu doctor.",
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding-bottom: 100px;
`;

const MobileContainer = styled.div`
  max-width: 640px;
`;

const leftMargin = "24px";
const topMargin = "27px";
const fontFamily = "Moderat";

const Title = styled.div`
  height: 40px;
  margin-left: ${leftMargin};
  margin-top: ${topMargin};

  font-family: ${fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;

  color: #14161a;
`;

const Subtitle = styled.div`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-left: ${leftMargin};
  margin-right: ${leftMargin};
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const ItsOver = () => {
  let params = new URL(location.toString()).searchParams;

  const userType = params.get("userType") || "Unknown";

  return (
    <RootContainer>
      <Toolbar>
        <Logo />
      </Toolbar>
      <MobileContainer>
        <Title>{strings.itsOver}</Title>
        <Subtitle>
          {userType === "Doctor" ? "" : strings.patientItsOver}
        </Subtitle>
      </MobileContainer>
    </RootContainer>
  );
};
