import * as React from "react";

const SvgCameraOn = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={28} height={28} fill="none" {...props}>
    <path
      d="M18.364 7.455H3.636C2.733 7.455 2 8.187 2 9.09v9.818c0 .904.733 1.637 1.636 1.637h14.728c.903 0 1.636-.733 1.636-1.637V9.091c0-.904-.733-1.636-1.636-1.636z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M20 16.455l5.44 2.04a.818.818 0 001.105-.764v-7.46a.818.818 0 00-1.105-.763L20 11.546v4.909z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCameraOn;
