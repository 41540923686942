/* eslint-disable no-restricted-globals */
import React, { useRef, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import * as OT from "@opentok/client";
import { Publisher, Subscriber } from "@opentok/client";
import { mixpanel } from "./MixpanelService";
import { flattenObject } from "./util";
import { SofiaIcon, Logo } from "./icons";
import {
  RootContainer,
  MainContainer,
  OuterSubscriberContainer,
  WaitingMessage,
  WaitingIcon,
  SubscriberContainer,
  PublisherBox,
  Toolbar,
  Body,
  WaitingBody,
  IntercomTrigger,
  SofiaLogo,
  Name,
  Title,
  NameContainer,
  IntercomTriggerContainer,
} from "./video-components";
import strings from "./strings";
import intercomFunction from "./intercomFunction";
import ArrowPermission from "./svgComponents/ArrowPermission";
import ArrowSabios from "./svgComponents/ArrowSabios";
import { isIOS, isSafari } from "react-device-detect";

declare global {
  interface Window {
    Intercom: any;
  }
}

export const VideoCall = () => {
  let params = new URL(location.toString()).searchParams;

  const token = params.get("token") || "";
  const sessionId = params.get("sessionId") || "";
  const apiKey = params.get("apiKey") || "";
  const consultId = params.get("consultId") || "Unknown";
  const sessionType = params.get("sessionType") || "Unknown";
  const userType = params.get("userType") || "Unknown";
  const patientFirstName = params.get("patientFirstName") || "";
  const patientFirstLastName = params.get("patientFirstLastName") || "";
  const patientSecondLastName = params.get("patientSecondLastName") || "";
  const doctorFirstName = params.get("doctorFirstName") || "";
  const doctorFirstLastName = params.get("doctorFirstLastName") || "";
  const doctorSecondLastName = params.get("doctorSecondLastName") || "";
  const doctorSpecialty = params.get("doctorSpecialty") || "";
  const email = params.get("email") || "";
  const userId = params.get("userId") || "";

  const getFullName = () => {
    if (userType === "Doctor") {
      return `${patientFirstName} ${patientFirstLastName} ${patientSecondLastName}`;
    } else if (userType === "Patient") {
      return `${doctorFirstName} ${doctorFirstLastName} ${doctorSecondLastName}`;
    }
    return "";
  };

  const getUserTitle = () => {
    if (userType === "Doctor") {
      return strings.patientTitle;
    } else if (userType === "Patient") {
      return doctorSpecialty;
    }
    return "";
  };

  const track = useCallback(
    (eventName: string, obj: any) => {
      const jsonObj = JSON.parse(JSON.stringify(obj));
      const payload = flattenObject({
        ...jsonObj,
        token,
        sessionId,
        apiKey,
        consultId,
        sessionType,
        roomType: sessionType === "routed" ? "OpenTokRouted" : "OpenTokRelay",
        userType,
      });
      // console.log("Event: ", eventName, " ", JSON.stringify(payload, null, 2));
      console.log("Event: ", eventName);
      mixpanel.track(eventName, payload);
    },
    [apiKey, token, sessionId, consultId, userType, sessionType],
  );

  const history = useHistory();

  const [previousStat, setPreviousStat] = useState<ConnectionStats | null>(
    null,
  );
  const [currentStat, setCurrentStat] = useState<ConnectionStats | null>(null);
  const [subscriber, setSubscriber] = useState<Subscriber | null>(null);
  const [publisher, setPublisher] = useState<Publisher | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isCameraDisabled, setIsCameraDisabled] = useState(false);

  const publisherRef = useRef<HTMLDivElement | null>(null);
  const subscriberRef = useRef<HTMLDivElement | null>(null);
  const [waitingMessage, setWaitingMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {
    // mixpanel.track("Video Start", { token, sessionId, apiKey });
    track("Video Start", {});
  }, [track, token, sessionId, apiKey]);

  let cameraButton: JSX.Element | null = null;
  let audioButton: JSX.Element | null = null;
  let hangUpButton = (
    <SofiaIcon
      type={"HangUp"}
      onClick={() => {
        if (publisher) {
          publisher.session?.disconnect();
        }

        track("Video hang up", {});
        if (
          userType !== "Doctor" &&
          (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/iPhone|iPad|iPod/i))
        ) {
          window.location.href = "sofiasalud://";
        } else {
          history.push(
            `/feedback?consultId=${consultId}&userType=${userType}&send=app`,
          );
        }
      }}
    />
  );

  if (isMuted) {
    audioButton = (
      <SofiaIcon
        type="Mute"
        onClick={() => {
          setIsMuted(false);
          if (publisher) {
            publisher.publishAudio(true);
          }
        }}
      />
    );
  } else {
    audioButton = (
      <SofiaIcon
        type="Unmute"
        onClick={() => {
          setIsMuted(true);
          if (publisher) {
            publisher.publishAudio(false);
          }
        }}
      />
    );
  }

  if (isCameraDisabled) {
    cameraButton = (
      <SofiaIcon
        type="CameraOff"
        onClick={() => {
          setIsCameraDisabled(false);
          if (publisher) {
            publisher.publishVideo(true);
          }
        }}
      />
    );
  } else {
    cameraButton = (
      <SofiaIcon
        type="CameraOn"
        onClick={() => {
          setIsCameraDisabled(true);
          if (publisher) {
            publisher.publishVideo(false);
          }
        }}
      />
    );
  }

  useEffect(() => {
    (async () => {
      if (publisherRef.current == null || subscriberRef.current == null) {
        return;
      }
      const currentPublisherRef: HTMLDivElement = publisherRef.current;
      const currentSubscriberRef: HTMLDivElement = subscriberRef.current;

      try {
        setWaitingMessage(strings.connectingToServer);
        setErrorStatus(false);

        const session = OT.initSession(apiKey, sessionId);
        let subscribers: Subscriber[] = [];

        session.on("streamDestroyed", function (event) {
          track("Video Stream Destroyed", event.stream);
          setWaitingMessage(strings.waitingForOtherPerson);
          setErrorStatus(false);
        });

        session.on("streamCreated", function (event) {
          track("Video Stream Created", event.stream);
          setWaitingMessage(strings.connectingCall);
          setErrorStatus(false);
          const subscriber = session.subscribe(
            event.stream,
            currentSubscriberRef,
            {
              insertMode: "append",
              width: "100%",
              height: "100%",
            },
            handleError,
          );
          setSubscriber(subscriber);
          subscribers.push(subscriber);
        });

        setWaitingMessage(strings.settingUpCameraAndMicrophone);
        setErrorStatus(false);

        let publisher = await new Promise<Publisher>((resolve, reject) => {
          var publisher = OT.initPublisher(
            currentPublisherRef,
            {
              insertMode: "replace",
              width: "100%",
              height: "100%",
              style: {
                buttonDisplayMode: "off",
              },
            },
            error => {
              if (!publisher.accessAllowed) {
                setWaitingMessage(strings.noAccess);
                setErrorStatus(true);
              }

              if (error) {
                reject(error);
                return;
              }
              resolve(publisher);
            },
          );
        });

        track("Video Publisher Created", {
          stream: publisher.stream,
          id: publisher.id,
          accessAllowed: publisher.accessAllowed,
        });

        setWaitingMessage(strings.connectingToServer);
        setErrorStatus(false);

        await new Promise((resolve, reject) => {
          session.connect(token, error => {
            if (error) {
              reject(error);
              return;
            }

            resolve();
          });
        });

        setPublisher(publisher);
        track("Video Session Connected", session);

        await new Promise((resolve, reject) => {
          session.publish(publisher, error => {
            if (error) {
              reject(error);
              return;
            }
            resolve();
          });
        });

        setWaitingMessage(strings.waitingForOtherPerson);
        setErrorStatus(false);
      } catch (error) {
        handleError(error);
      }

      function getErrorMsg(msg) {
        if (isIOS && !isSafari) {
          return strings.errorNotUsingSafariOnIOS;
        }
        const found = strings.errors.find(element => element.origin === msg);
        if (found) return found.message;
        return strings.defaultError;
      }

      function handleError(error) {
        if (error) {
          track("Video Error", error);

          if (error.message) {
            setWaitingMessage(`Error - ${getErrorMsg(error.message)}`);
            setErrorStatus(true);
          }
        }
      }

      // END
    })();
  }, [
    track,
    publisherRef,
    subscriberRef,
    apiKey,
    sessionId,
    token,
    consultId,
    userType,
  ]);

  useEffect(() => {
    if (!subscriber) {
      return;
    }
    const interval = setInterval(() => {
      (subscriber as any).getStats((err, stats) => {
        if (err) {
          return;
        }
        // console.log("Error: ", err);
        // console.log("Stats: ", JSON.stringify(stats, null, 2));

        setCurrentStat(stats);
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [track, subscriber]);

  useEffect(() => {
    if (currentStat === null) {
      return;
    }

    if (previousStat === null && currentStat !== null) {
      setPreviousStat(currentStat);
      setCurrentStat(null);
      return;
    }

    if (
      previousStat !== null &&
      currentStat !== null &&
      previousStat.timestamp !== currentStat.timestamp
    ) {
      const output = calculateStatsDelta(previousStat, currentStat);

      const stream = subscriber ? subscriber.stream : undefined;
      track("Video and Audio Network Stats", {
        output,
        currentStat,
        stream,
      });

      // console.log("---- currentStat", JSON.stringify(currentStat, null, 2));
      // console.log("---- output", JSON.stringify(output, null, 2));

      // Things changed
      setPreviousStat(currentStat);
      setCurrentStat(null);
      return;
    }
  }, [track, currentStat, previousStat, subscriber]);

  useEffect(() => {
    intercomFunction(process.env.REACT_APP_INTERCOM_ID);
    let intercomObj = {
      app_id: process.env.REACT_APP_INTERCOM_ID,
      custom_launcher_selector: "#custom_launcher_selector",
      hide_default_launcher: true,
    };
    if (email && userId) {
      intercomObj["email"] = email;
      intercomObj["user_id"] = userId;
    }
    window.Intercom("boot", intercomObj);
  }, [email, userId]);

  return (
    <RootContainer>
      <MainContainer>
        <OuterSubscriberContainer>
          <Body>
            <SofiaLogo>
              <Logo />
            </SofiaLogo>
            <WaitingBody>
              {errorStatus ? (
                <WaitingIcon>
                  <SofiaIcon type={"Error"} />
                </WaitingIcon>
              ) : null}
              <WaitingMessage>{waitingMessage}</WaitingMessage>
            </WaitingBody>
            {waitingMessage === strings.settingUpCameraAndMicrophone ? (
              <ArrowPermission />
            ) : null}
            {errorStatus ? (
              <IntercomTriggerContainer id="custom_launcher_selector">
                <IntercomTrigger>{strings.openIntercom}</IntercomTrigger>
                <ArrowSabios />
              </IntercomTriggerContainer>
            ) : null}
          </Body>
          <SubscriberContainer id="subscriber" ref={subscriberRef} />
        </OuterSubscriberContainer>
        <PublisherBox>
          <div id="publisher" ref={publisherRef} />
        </PublisherBox>
      </MainContainer>
      <Toolbar>
        <NameContainer>
          <Name>{getFullName()}</Name>
          <Title>{getUserTitle()}</Title>
        </NameContainer>
        {cameraButton}
        {audioButton}
        {hangUpButton}
      </Toolbar>
    </RootContainer>
  );
};

interface ConnectionStats {
  timestamp: number;
  audio: {
    packetsLost: number;
    packetsReceived: number;
    bytesReceived: number;
  };
  video: {
    packetsLost: number;
    packetsReceived: number;
    bytesReceived: number;
    frameRate: number;
  };
}

function calculateStatsDelta(
  previousStat: ConnectionStats,
  currentStat: ConnectionStats,
) {
  const bitsPerByte = 8;
  const bitsDelta = Math.min(
    0,
    bitsPerByte *
      (currentStat.video.bytesReceived -
        previousStat.video.bytesReceived +
        (currentStat.audio.bytesReceived - previousStat.audio.bytesReceived)),
  );
  const timeDelta = Math.min(0, currentStat.timestamp - previousStat.timestamp);
  const timeDeltaInSec = timeDelta / 1000.0;
  let bps = 0;
  if (timeDeltaInSec !== 0) {
    bps = bitsDelta / timeDeltaInSec;
  }
  const kbps = bps / 1000.0;
  const packetsReceivedDelta = Math.min(
    0,
    currentStat.video.packetsReceived -
      previousStat.video.packetsReceived +
      (currentStat.audio.packetsReceived - previousStat.audio.packetsReceived),
  );
  const packetsLostDelta = Math.min(
    0,
    currentStat.video.packetsLost -
      previousStat.video.packetsLost +
      (currentStat.audio.packetsLost - previousStat.audio.packetsLost),
  );
  const packetsTotalDelta = packetsReceivedDelta + packetsLostDelta;
  let packetLossRatio = 0;
  if (packetsTotalDelta !== 0) {
    packetLossRatio = packetsLostDelta / packetsTotalDelta;
  }
  return {
    bitsDelta: Math.round(bitsDelta),
    kbps: Math.round(kbps),
    packetLossRatio,
    packetsLostDelta,
    packetsReceivedDelta,
    timeDelta: Math.round(timeDelta),
  };
}
