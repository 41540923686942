import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { VideoChatRoom } from "./VideoChatRoom";
import { Home } from "./Home";
import { CreateRoom } from "./CreateRoom";
import { VideoCall } from "./VideoCall";
import { TwilioVideoCall } from "./TwilioVideoCall";
import { Feedback } from "./Feedback";
import { ItsOver } from "./ItsOver";
import { VideoCall as VideoCallEmbedded } from "./VideoCallEmbedded";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={({ match }) => {
            return <Home />;
          }}
        />
        <Route exact path="/video-chat-room">
          <VideoChatRoom isRouted={true} />
        </Route>
        <Route exact path="/video-chat-room/relayed">
          <VideoChatRoom isRouted={false} />
        </Route>
        <Route exact path="/create-room">
          <CreateRoom />
        </Route>
        <Route exact path="/video-call">
          <VideoCall />
        </Route>
        <Route exact path="/vc">
          <VideoCallEmbedded />
        </Route>
        <Route exact path="/video-call-twilio">
          <TwilioVideoCall />
        </Route>
        <Route exact path="/feedback">
          <Feedback />
        </Route>
        <Route exact path="/gracias">
          <ItsOver />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
