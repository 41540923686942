import * as React from "react";

const SvgCameraOff = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={25} height={24} fill="none" {...props}>
    <path
      d="M16 7H2.5A1.5 1.5 0 001 8.5v9A1.5 1.5 0 002.5 19H16a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0016 7zM3.25 3l18 18.75"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M17.5 15.25l4.987 1.87a.75.75 0 001.013-.7V9.582a.75.75 0 00-1.013-.7L17.5 10.75v4.5z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgCameraOff;
