import * as React from "react";

const SvgHangUp = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={29} height={12} fill="none" {...props}>
    <path
      clipRule="evenodd"
      d="M5.216 2.213h-.013A5.367 5.367 0 001 7.456v1.061a1.789 1.789 0 001.789 1.79H7.26c.988 0 1.789-.802 1.789-1.79s.8-2.225 1.789-2.225h7.155c.988 0 1.79 1.237 1.79 2.225s.8 1.79 1.788 1.79h4.472c.988 0 1.79-.802 1.79-1.79v-1.06a5.367 5.367 0 00-4.203-5.24h-.014a48.434 48.434 0 00-18.401-.004z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgHangUp;
