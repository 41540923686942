import * as React from "react";

const SvgUnmute = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M12 19.5v3.75M13.5.75h-3a3 3 0 00-3 3v9.75a3 3 0 003 3h3a3 3 0 003-3V3.75a3 3 0 00-3-3z"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 9.75V12a7.5 7.5 0 01-15 0V9.75"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgUnmute;
