export function flattenObject(ob: any, prefix?: string) {
  const toReturn = {};
  prefix = prefix ? prefix + "." : "";

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === "object" && ob[i] !== null) {
      // Recursion on deeper objects
      Object.assign(toReturn, flattenObject(ob[i], prefix + i));
    } else {
      toReturn[prefix + i] = ob[i];
    }
  }
  return toReturn;
}
