import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";

const Container = styled.div`
  padding: 2em 2em 2em 2em;
`;

// const Item = styled.li`
//   padding: 1em 0em 0em 1em;
// `;

export const Home = () => {
  //   <Item>
  //   <Link to="/video-network-test">Video network test</Link> - &nbsp;{" "}
  //   <span>Test network to see how well it can support video chat</span>
  // </Item>

  return (
    <Container>
      <h1>Sofía Call</h1>
    </Container>
  );
};
