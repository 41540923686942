export default {
  connectingCall: "Llamada de conexión...",
  connectingToServer: "Conectando al servidor...",
  defaultError:
    "Algo no está funcionando, contacta a los sabios para que te ayuden.",
  noAccess:
    "No se puede acceder a la cámara y al micrófono. Da los permisos y actualiza página.",
  openIntercom: "Habla con un Sabio",
  patientTitle: "Paciente",
  settingUpCameraAndMicrophone: "Configurar cámara y micrófono...",
  waitingForOtherPerson: "Esperando a que otra persona se conecte",
  errorNotUsingSafariOnIOS:
    "La video consulta no funciona con Google Chrome en dispositivos iOS. Intenta otro navegador, como Safari.",
  errors: [
    {
      origin:
        "The selected voice or video devices are unavailable. Verify that the chosen devices are not in use by another application. (getUserMedia error: NotReadableError)",
      message:
        "Parece que tu navegador no funciona para videollamadas, intenta usando Chrome o Safari.",
    },
    {
      origin:
        "OT.Publisher Access Denied: Permission Denied: End-user denied permission to hardware devices (getUserMedia error: NotAllowedError)",
      message:
        "Para acceder a la videoconsulta necesitas dar los permisos de cámara y micrófono. Presiona el candado del navegador o actualiza la página para dar los permisos.",
    },
    {
      origin:
        "Session.publish :: Could not publish in a reasonable amount of time",
      message:
        "Hubo un error conectando tu videoconsulta, revisa tu conexión a internet e intenta otra vez actualizando la página y esperando en esta ventana.",
    },
  ],
};
