import { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";

const fallbackPath = "fallback";

export interface FallbackConfig {
  fallbackNumber: number;
}

// type FallbackConfigUpdate = Partial<FallbackConfig>;

class FirebaseService {
  constructor() {
    const configString = process.env["REACT_APP_FIREBASE_CONFIG"] || "";
    const config = JSON.parse(configString);
    // console.log("REACT_APP_FIREBASE_CONFIG:", config);

    firebase.initializeApp(config);
  }

  database() {
    return firebase.database();
  }

  useFallbackConfig(params: {
    resultCallback: (fallbackConfig: FallbackConfig) => void;
    errorCallback: (errorMessage: string) => void;
  }) {
    const { resultCallback, errorCallback } = params;
    useEffect(() => {
      console.log("Subscribing: ", fallbackPath);
      const ref = this.database().ref(fallbackPath);
      const callback = ref.on("value", (dataSnapshot, error) => {
        if (error) {
          errorCallback(error);
          return;
        }
        const val: FallbackConfig | null = dataSnapshot.val();
        if (!val) {
          const config: FallbackConfig = {
            fallbackNumber: 1,
          };
          ref.set(config);
          return;
        }

        resultCallback(val);
      });

      return () => {
        ref.off("value", callback);
        console.log("Clean up effect");
      };
    }, [resultCallback, errorCallback]);
  }

  incrementFallbackCount() {
    this.database()
      .ref(fallbackPath)
      .child("fallbackNumber")
      .transaction(function (fallbackNumber) {
        return (fallbackNumber || 0) + 1;
      });
  }
}
// incrementFallbackCount() {
//   this.database().ref(fallbackPath)
// }

// on(
//   eventType: EventType,
//   callback: (a: firebase.database.DataSnapshot, b?: string | null) => any,
//   cancelCallbackOrContext?: Object | null,
//   context?: Object | null
// ): (a: firebase.database.DataSnapshot | null, b?: string | null) => any;

export const firebaseService = new FirebaseService();
