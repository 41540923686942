/* eslint-disable no-restricted-globals */

import * as React from "react";
import styled from "styled-components";
import styledNative from "styled-components/native";
import { useHistory } from "react-router-dom";
import { Logo } from "./icons";
import { Toolbar } from "./video-components";
import { mixpanel } from "./MixpanelService";
import {
  sofiaPurple,
  backgroundGray,
  backgroundPurple,
  blueGray,
} from "./colors";
import { TouchableOpacity, Text } from "react-native";

const strings = {
  title: "Feedback",
  subtitle: "¿Cómo estuvo la calidad de la videoconsulta?",
  leaveAComment: "Agrega un comentario",
  buttonTitle: "Enviar",
  itsOver: "La videoconsulta ha terminado",
  patientItsOver:
    "Regresa a tu app para recibir las indicaciones de parte de tu doctor.",
};

interface FeedbackSelected {
  "Todo salió bien"?: boolean;
  "Sin audio"?: boolean;
  "Mal audio"?: boolean;
  "Sin video"?: boolean;
  "Mal video"?: boolean;
  "Desfase entre video y audio"?: boolean;
  "Terminó repentinamente"?: boolean;
  Otro?: boolean;
}

const options: (keyof FeedbackSelected)[] = [
  "Todo salió bien",
  "Sin audio",
  "Mal audio",
  "Sin video",
  "Mal video",
  "Desfase entre video y audio",
  "Terminó repentinamente",
  "Otro",
];

const RootContainer = styled.div`
  /* padding: 2em 2em 2em 2em; */
  /* max-width: 640px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding-bottom: 100px;
`;

const MobileContainer = styled.div`
  /* padding: 2em 2em 2em 2em; */
  max-width: 640px;
  /* flex: 1; */
`;

const leftMargin = "24px";
const topMargin = "27px";
const fontFamily = "Moderat";

const Title = styled.div`
  height: 40px;
  margin-left: ${leftMargin};
  margin-top: ${topMargin};

  font-family: ${fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;

  color: #14161a;
`;

const Subtitle = styled.div`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin-left: ${leftMargin};
  margin-right: ${leftMargin};
  margin-top: 32px;
  margin-bottom: 16px;
`;

const SubmitButton = styled.div`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 56px;
  margin-left: ${leftMargin};
  margin-right: ${leftMargin};
  margin-top: 32px;
  background-color: ${sofiaPurple};
  height: 56px;
  color: #fff;
  text-align: center;
  border-radius: 28px;
`;

const PillButton = styled.span`
  background-color: ${(props: { isSelected: boolean }) =>
    props.isSelected ? backgroundPurple : backgroundGray};
  color: ${(props: { isSelected: boolean }) =>
    props.isSelected ? sofiaPurple : blueGray};
  height: 48px;
  font-size: 16px;
  padding: 16px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 18px;
  display: inline-block;
`;

interface FeedbackOptionsProps {
  feedbackSelected: FeedbackSelected;
  setFeedbackSelected: React.Dispatch<React.SetStateAction<FeedbackSelected>>;
}

export const FeedbackOptions: React.FunctionComponent<FeedbackOptionsProps> = (
  props: FeedbackOptionsProps
) => {
  return (
    <div>
      {options.map((option, index) => {
        return (
          <PillButton
            isSelected={!!props.feedbackSelected[option]}
            key={`option-${index}`}
          >
            <TouchableOpacity
              onPress={() => {
                const updatedFeedbackSelected = {
                  ...props.feedbackSelected,
                  ...{ [option]: !props.feedbackSelected[option] },
                };

                if (option === "Todo salió bien") {
                  // Unset everything else
                  for (const key of Object.keys(updatedFeedbackSelected)) {
                    if (key !== option) {
                      updatedFeedbackSelected[key] = false;
                    }
                  }
                } else {
                  updatedFeedbackSelected["Todo salió bien"] = false;
                }

                props.setFeedbackSelected(updatedFeedbackSelected);
              }}
            >
              <Text>{option}</Text>
            </TouchableOpacity>
          </PillButton>
        );
      })}
    </div>
  );
};

const CommentsTextField = styledNative.TextInput`
  height: 36px;
  width: 90%;
  margin-left: 5%;
  border-bottom-color: black;
  border-bottom-width: 1px;
  margin-top: 36px;
`;

export const Feedback = () => {
  let params = new URL(location.toString()).searchParams;

  const consultId = params.get("consultId") || "Unknown";
  const userType = params.get("userType") || "Unknown";
  const send = params.get("send") || "web";
  const sessionId = params.get("sessionId") || "";
  const username = params.get("username") || "";

  const [feedbackSelected, setFeedbackSelected] = React.useState<
    FeedbackSelected
  >({});

  const history = useHistory();

  const [comment, setComment] = React.useState<string>("");

  React.useEffect(() => {
    const feedback = {
      consultId,
      userType,
      send,
      sessionId,
    };

    if (username) {
      feedback["username"] = username;
    }

    mixpanel.track("Web Feedback - Page Loaded", feedback);
  }, [consultId, userType, send, sessionId, username]);

  return (
    <RootContainer>
      <Toolbar>
        <Logo />
      </Toolbar>
      <MobileContainer>
        <Title>{strings.title}</Title>
        <Subtitle>{strings.subtitle}</Subtitle>
        <FeedbackOptions
          feedbackSelected={feedbackSelected}
          setFeedbackSelected={setFeedbackSelected}
        />
        <CommentsTextField
          placeholder={strings.leaveAComment}
          value={comment}
          onChangeText={(value) => {
            setComment(value);
          }}
        />
        <TouchableOpacity
          onPress={() => {
            console.log("Submit", { feedbackSelected, comment });
            // Doctor Post-consult Feedback
            const feedback = {
              ...feedbackSelected,
              consultId,
              userType,
              comment,
              sessionId,
            };

            if (sessionId) {
              feedback["sessionId"] = sessionId;
            }

            if (username) {
              feedback["username"] = username;
            }

            mixpanel.track("Web Feedback - Feedback Submitted", feedback);

            if (
              send === "app" &&
              (navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/iPhone|iPad|iPod/i))
            ) {
              window.location.href = "sofiasalud://";
            } else if (
              send === "call" &&
              (navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/iPhone|iPad|iPod/i))
            ) {
              window.location.href = "sofiacall://";
            } else {
              history.push(
                `/gracias?consultId=${consultId}&userType=${userType}`
              );
            }
          }}
        >
          <SubmitButton>{strings.buttonTitle}</SubmitButton>
        </TouchableOpacity>
      </MobileContainer>
    </RootContainer>
  );
};
